.as-content-view-map {
    width: 71%;
    height: 100%;
    float: right;
}

.as-content-map-view{
    border-radius: 0 3px 3px 0;
    background: #f3f7fa !important;
    border-left: 3px solid #8ec3fb;
    height: 100%;
    width: 100%;
}

.as-w-99-8 {
    width : 99.8% !important;
}

.as-w-99-8 .as-content-map-view{
    border-radius: 3px !important;
}