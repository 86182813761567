.as-tab-content-body {
  height: 100%;
  padding-bottom: 40px;
  /* height: calc(100% - 10px);
    padding-bottom: 20px; */
}

.as-tab-content-footer {
  /* height: 10px; */
  height: 0px;
  background: transparent;
}

.tab-content-height {
  /* height: calc(100% - 10%) !important; */
  /* height: calc(100% - -5%) !important; */
}
