.chat-window-container {
    height: 100%;
    background-color: #f3f7fa;
    /* box-shadow: -1px 0px 20px 0px #d8e5ee; */
}

.chat-messages-container {
    height: 100%;
    overflow-y: scroll;
    margin-right: -5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.input-container {
    height: 10%;
    border-radius: 50;
    border: 1.5px solid #ced4da;
    border-right-width: 0px;
}

.pin-icon {
    height: 18px;
    width: 18px;
    margin-top: 9px;
    margin-left: 7px;
}

.reload-chat-btn {
    margin-block: 10px;
    font-size: 11px;
    border: 0.5px solid #3166ee !important;
    color: #3166ee;
    box-shadow: none;
    text-transform: capitalize;
    border-radius: 20px;
}

._input {
    width: 87%;
    outline: none;
    border: 0px solid rgb(204, 204, 204);
    height: 100%;
    font-size: 12px;
    padding-left: 10px;
    background-color: #f4f7fa;
    resize: none;
    padding-top: 10px;
}

.send-icon-container {
    display: inline-block;
    background: linear-gradient(-135deg, #1dc4e9 0%, #3166ee 100%);
    height: 100%;
    width: 13%;
    text-align: center;
    position: relative;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
    border-color: #3166ee;
    text-align: center;
    vertical-align: middle;
    border: none;
}

.send-icon {
    height: 12px;
    width: 12px;
    cursor: pointer;
}

.user-img {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 50%;
    vertical-align: middle;
    border-style: none;
    margin-right: 10px;
}

.media {
    display: flex;
    align-items: flex-start;
}

.chat-window-container .chat-messages-container .chat-messages .photo-table {
    padding-right: 15px;
}

.media-body {
    flex: 1 1;
}

.chat-time {
    margin: 9px 8px 0 10px;
    color: #888;
    font-size: 12px;
    padding-bottom: 10px;
}

.chat-menu-reply {
    text-align: right;
}

.chat-menu-reply div p {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 4px;
    margin-right: 25px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-content>div,
.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div {
    position: relative;
    overflow: visible;
    display: inline-block;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div:before {
    content: "";
    z-index: 5;
    font-size: 30px;
    position: absolute;
    bottom: 0px;
    right: 10px;
    width: 0;
    height: 0;
    border-bottom: 20px solid #fff;
    border-right: 20px solid transparent;
}

p {
    font-size: 13px;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div p:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div p:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-content>div .chat-cont,
.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div .chat-cont {
    padding: 15px 20px;
    white-space: pre-line;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-reply>div p {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 0px;
    margin-right: 25px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-content>div:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 20px solid #388bec;
    border-left: 20px solid transparent;
}

.chat-window-container .chat-messages-container .chat-messages .chat-menu-content>div p {
    background: linear-gradient(-135deg, #1dc4e9 0%, #3166ee 100%);
    color: #fff;
    border-radius: 5px;
    margin-bottom: 4px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
}

.close-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    border-radius: 50%;
}

.chat-download {
    height: 25px;
    width: 25px;
    background-color: #000000d3;
    border-radius: 15px;
    text-align: center;
    padding-top: 1px;
    position: absolute;
    z-index: 99;
    top: -6px;
    cursor: pointer;
}

.chat-download-right {
    right: -6px
}

.chat-download-left {
    left: -6px
}