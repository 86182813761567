.tbl-space {
  border-collapse: separate !important;
  border-spacing: 0 3px !important;
}

.pointer {
  cursor: pointer;
}

.leafletMap {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px 3px 3px 0px;
}


.leafletMap svg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.leafletMap circle {
  fill: transparent;
}

.leafletMap .text {
  font-family: Roboto, sans-serif;
  fill: #fff;
  font-size: 8px;
  font-weight: bold;
}

.cluster-markers {
  margin-top: 0;
  margin-bottom: 1rem;
  height: auto;
  max-height: 150px;
  padding: 0px;
  overflow-x: scroll;
}

.leaflet-div-icon {
  height: 1px !important;
  width: 1px !important;
}

.leaflet-tooltip {
  padding: 2px 6px !important;
}

.leaflet-tooltip-bottom::before {
  display: none !important;
}

.leaflet-tooltip-bottom {
  margin-top: 3px;
}

.leaflet-popup-content {
  margin: 13px 5px !important;
  width: 350px !important;
}

.popup-status-text {
  /* position: absolute;
  z-index: 99;
  padding-left: 4px; */
}

.vehicle-container td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
}

.vehicle-container td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}

.vehicle-adddress {
  font-size: 10px;
  white-space: nowrap;
  width: 365px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 12px;
  color: #494f55;
  margin: -1px -10px 2px 1px !important;
}


.accordion .accordion-title {
  width: 100%;
  border: none;
  background-color: #6e7482 !important;
  border-color: transparent !important;
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  font-size: 13px;
}


.icon-arrow {
  padding: 0px 10px;
  text-align: center;
  margin-top: 4px;
}

.hideMarker {
  display: none !important;
}

.map-custom-control {
  position: absolute !important;
  z-index: 2 !important;
  right: 60px !important;
  top: 10px;
  height: 40px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.map-custom-control span {
  margin: 0px 8px;
  font-size: 14px;
}

.font-20 {
  font-size: 16px;
}

.leafletMapLoader {
  width: 100%;
  height: 100%;
  background: #00000075;
  position: absolute;
  z-index: 999;
}

.leafletMapLoader img {
  width: auto;
  height: 150px;
  margin: auto 0;
  border-radius: 110px;
  object-fit: contain;
  position: absolute;
  left: 26%;
  top: 29%;
  border-radius: 20px;
}

.ml-10 {
  margin-left: 10px;
}


.text-vehicle {
  font-size: 12px;
  color: #637586;
}

.custom-control .dropdown .dropdown-toggle::after {
  display: none;
}

#dropdown-basic-search-map {
  background-color: transparent;
  box-shadow: none;
  position: relative;
  top: -10px;
  left: -10px;
}


.map-checkbox {
  height: 12px;
  width: 20px;
}


#select-poi {
  padding: 0px;
  height: 30px;
  width: 220px;
  font-size: 12px;
  border-radius: 0px;
  border-radius: 2px;
}


.pac-target-input {
  /* height: 30px;
  position: relative;
  top: -6px;
  margin-right: 15px; */
}



.content-part-view {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dashboard-card {
  height: 200px;
}

#overviewReportChart tspan {
  font-size: 12px !important;
}

tspan {
  font-size: 13px !important;
}

.vh-92 {
  /* height: 92vh !important; */
}

.sidebar-tabs-hide {
  display: none;
}


.sidebar-tabs-show {
  display: block;
}

.zoom-controls {
  position: absolute !important;
  z-index: 999 !important;
  right: 10px !important;
  bottom: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 10px;
}

.zoom-controls img {
  height: 15px;
  width: 15px;
  cursor: pointer;
  display: block;
}


.task-list-horizontal {
  width: 75%;
  height: 70px;
}

/* The heart of the matter */
.task-list-horizontal>.row {
  overflow-x: auto !important;
  white-space: nowrap !important;
  display: inline-block;
}

.task-list-horizontal>.row>.col-md-3 {
  display: inline-block !important;
  float: none !important;
}

.status-card-item {
  max-width: 130px;
  margin-right: 10px;
}

.driver-info-card {
  width: auto;
  min-width: 200px;
}


.relative {
  position: relative;
  z-index: 2;
}

.slider {
  position: relative;
  /* z-index: 1; */
  top: -89px;
  width: 82%;
  background: #fff;
  padding: 10px;
  box-shadow: 8px 4px 20px 0px #989898;
  border-radius: 10px;
}

.btn-edit-id {
  padding: 9px 6px;
  background: #ebebeb;
  margin-top: 14px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.gm-ui-hover-effect:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}


.clear-poi-single-selection {
  position: absolute;
  z-index: 1;
  background: #1263ee;
  padding: 11px 18px;
  border-radius: 40px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 20px;
  box-shadow: 1px 2px 12px 0px #7e7e7e;
  bottom: 20px;
  left: 36%;
  cursor: pointer;
}


.sidebar-shadow {
  box-shadow: 0 0 40px rgb(0 0 0 / 25%);
  border-left: solid 5px #FFF;
}