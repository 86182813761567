@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  overflow: hidden !important;
  min-height: 100vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 120% !important;
}

.v-center {
  align-items: center;
}

.radius-50 {
  border-radius: 50%;
}

.selection-none {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.modal.footer {
  border: none !important;
}

.modal-footer {
  display: block !important;
  text-align: right !important;
}

.rdt input {
  font-size: 12px;
}

.textarea {
  outline: none;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 12px;
  margin-top: 7px;
}

.textarea:not(.resize-vertically) {
  resize: none;
}

.radio {
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #1360ee !important;
  border-radius: 50%;
  outline: none;
  background-color: #f2f7ff;
}

input[type="radio"]:before {
  content: "" !important;
  display: block;
  width: 60% !important;
  height: 60% !important;
  margin: 20% 20% !important;
  border-radius: 50%;
}

input[type="radio"]:checked {
  border: 1px solid #1360ee !important;
  outline: none;
  background-color: #1360ee !important;
}

.form-check-input:checked {
  padding: 0px !important;
}

input[type="radio"]:checked:before {
  background: #fdfdfd !important;
}

.tab-container {
  /* height: 68vh;
    overflow-y: auto; */
}

.overflow-auto {
  overflow: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.h-20p {
  height: 20px !important;
}

.h-60vh {
  height: 60vh !important;
  overflow-y: scroll !important;
}

.h-65vh {
  height: 65vh !important;
  overflow-y: scroll !important;
}

.w-200px {
  width: 220px !important;
}

.navbar .nav-link {
  color: #fff !important;
}

.bg-e {
  background: #eeedf2 !important;
}

.navbar {
  box-shadow: none !important;
}

.sidenav .navbar-brand {
  padding: 10px 0px !important;
}

.sidenav-header {
  height: 2.875rem;
}

.sidenav.fixed-left + .main-content {
  margin-left: 8rem;
}

.navbar-vertical.navbar-expand-xs {
  /* max-width: 140px !important; */
}

.navbar-vertical .navbar-nav > .nav-item .nav-link.active {
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  /* height: 85px; */
}

.mn-c {
  padding: 0px 10px;
}

.nav-pills .nav-link {
  background-color: gray;
}

.nav.nav-pills .nav-item {
  margin: 0px 5px;
  font-size: 13px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #3964c2 !important;
  background-color: #caddfe !important;
}

.main-tab-chldrn {
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
  color: #4f5462;
  background: #f2f2f2;
  /* background: #e2e2e2; */
  text-align: center;
  width: 100%;
}

/* .main-tab-chldrn-active {
  color: #3964c2 !important;
  background-color: #caddfe !important;
} */

.table-vhcls thead th {
  padding: 10px;
  text-transform: capitalize;
  letter-spacing: 0px;
  border-bottom: 1px solid #e9ecef;
  border-top: none;
  font-size: 11px !important;
}

.tooltips {
  color: #fff;
  background: #222 !important;
  border: 1px solid transparent;
}

.__react_component_tooltip {
  z-index: 9999999 !important;
}

.allActivebtn {
  background-color: #cae0f2 !important;
  color: #1a60eb !important;
  border-bottom: 3px solid #1a60eb !important;
  width: 20%;
}

.allActivebtn:hover {
  background-color: #1a60eb !important;
  color: white !important;
}

.allActivebtnDark {
  background-color: #1a60eb !important;
  color: white !important;
}

.allActivebtn:after {
  border-color: #1a60eb rgba(0, 0, 0, 0) !important;
}

.movingButton {
  background-color: #d3f1cb !important;
  border-bottom: 3px solid #0cbe0e !important;
  color: #458437 !important;
  width: 20%;
}

.movingButton:hover {
  background-color: #0cbe0e !important;
  color: white !important;
}

.movingButtonDark {
  background-color: #0cbe0e !important;
  color: white !important;
}

.movingButton:after {
  border-color: #0cbe0e rgba(0, 0, 0, 0) !important;
}

.idlingButton {
  background-color: #fef6e3 !important;
  border-bottom: 3px solid #f5a713 !important;
  color: #bea174 !important;
  width: 20%;
}

.idlingButton:hover {
  background-color: #f5a713 !important;
  color: white !important;
}

.idlingButtonDark {
  background-color: #f5a713 !important;
  color: white !important;
}

.idlingButton:after {
  border-color: #f5a713 rgba(0, 0, 0, 0) !important;
}

.parkingButton {
  background-color: #ffebed !important;
  border-bottom: 3px solid #ed1e10 !important;
  color: #a85456 !important;
  width: 20%;
}

.parkingButton:hover {
  background-color: #ed1e10 !important;
  color: white !important;
}

.parkingButtonDark {
  background-color: #ed1e10 !important;
  color: white !important;
}

.parkingButton:after {
  border-color: #ed1e10 rgba(0, 0, 0, 0) !important;
}

.noconnectionButton {
  background-color: #ececec !important;
  border-bottom: 3px solid #7c7c7c !important;
  color: #9e9ea0 !important;
  width: 20%;
}

.noconnectionButton:hover {
  background-color: #7c7c7c !important;
  color: white !important;
}

.noconnectionButtonDark {
  background-color: #7c7c7c !important;
  color: white !important;
}

.noconnectionButton:after {
  border-color: #7c7c7c rgba(0, 0, 0, 0) !important;
}

.activeTab {
  color: white !important;
}

.activeTab:after {
  /* border-style: solid;
  border-width: 0px 10px 10px; */
  border-bottom: 20px solid red;
  border-right: 20px solid transparent;
  content: "";
  display: block;
  position: relative;
  top: 10px;
  margin: auto;
  width: 0;
  z-index: -1;
  margin-top: -14px;
  transform: rotate(-45deg);
  border-bottom-left-radius: 5px;
  /* -webkit-transform: rotate(-180deg); */
}

.font-13 {
  font-size: 13px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-11 {
  font-size: 11px;
}

.pointer {
  cursor: pointer !important;
}

.cursor-auto {
  cursor: auto !important;
}

.Collapsible__contentInner {
  border: 1px solid #ebebeb;
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  text-decoration: none;
  color: #333333;
  position: relative;
  padding: 4px;
  background: #6e7482;
  color: white;
  font-size: 13px;
  font-weight: bold;
  border-radius: 3px 3px 0px 0px;
  margin-bottom: 4px;
  padding: 1px !important;
}

.Collapsible .Collapsible__trigger:after {
  font-family: "FontAwesome" !important;
  content: "\f107" !important;
  position: absolute !important;
  right: 10px !important;
  top: 7px !important;
  display: block !important;
  transition: transform 300ms !important;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.acd-tbl td {
  padding: 0px;
}

.acrdl-v-i:not(.arrow) {
  width: 30px !important;
}

.acrdl-v-i.arrow {
  width: 18px !important;
}

.acrd-main-div .card-body {
  padding: 10px !important;
  /* margin-left: -11px; */
}

.acd-tbl .table td,
.table th {
  padding: 10px;
}

.ellipsis-180 {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-250 {
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.mt-2_5 {
  margin-top: 0.7rem;
}

.mt-2_7 {
  margin-top: 0.9rem;
}

.p-1_5 {
  padding: 0.4rem !important;
}

.searchInput {
  width: 99.5%;
  font-size: 13px;
  padding: 6px 12px;
  line-height: 1.428571429;
  height: 31px !important;
  border-color: #e2e2e2;
  border-width: 1px;
  border-radius: 5;
  outline: none;
  border-radius: 5px;
}

.searchInput:focus {
  border-color: #29b1c0;
  outline: #0cbe0e;
}

.input-style {
  font-size: 12px;
  height: 30px;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  padding: 0px 10px;
  border-radius: 5px;
}

.input-focus:focus {
  border-color: #29b1c0;
  outline: #0cbe0e;
}

.gm-style div[aria-hidden="true"] {
  background-color: white !important;
  padding: 5px;
  border-radius: 5px;
  top: 45px;
  position: relative;
  font-size: 12px !important;
  box-shadow: 1px 0.7px 7px #888888;
}

.top-32 {
  top: 32px !important;
}

#weeklyAfterOfficeHourChart,
#weeklyExcessKilometerUsage,
#WeeklyAttendanceReport,
#WeeklyAfterOfficeHourKM {
  height: 165px !important;
}

.gm-style .gm-style-iw-c {
  padding-bottom: 0px !important;
}

.marker {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -25px;
  left: -25px;
  transform: translateY(-50%);
}

.marker-lable {
  background-color: white;
  padding: 5px;
  position: relative;
  display: block ruby;
  left: -40px;
  top: 3px;
  border-radius: 4px;
  width: auto;
  text-align: center;
  display: inline-block;
  min-width: 90px;
}

.marker-card {
  position: absolute;
  width: 330px;
  height: auto;
  padding: 10px;
  bottom: 60px;
  left: -160px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  z-index: 99999;
}

._ibani_9277 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.marker-card-after {
  position: absolute;
  left: 50%;
  bottom: 46px;
  height: 0px;
  width: 0px;
  margin-left: -15px;
  border-width: 15px 15px 0px;
  border-color: rgb(204, 204, 204) transparent transparent;
  border-style: solid;
  z-index: 999999;
}

.marker-card-after-content {
  position: absolute;
  left: 50%;
  bottom: -1px;
  height: 0px;
  width: 0px;
  border-color: #ffffff transparent transparent;
  border-left-color: transparent;
  border-style: solid;
  border-left-style: solid;
  border-top-width: 14px;
  border-left: 14px solid transparent;
  border-right-width: 14px;
  margin-left: -14px;
}

.card-image {
  position: absolute;
  right: 5px;
  top: 90px;
  border: 1px solid rgb(204, 204, 204);
  padding: 4px;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.popup-content {
  font-size: 14px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
}

.popup-content tr td:nth-child(1) {
  width: 1%;
  font-size: 14px;
  color: #5d5d5d;
  font-weight: 500 !important;
  padding: 2px 5px 2px;
}

.first_left {
  width: 35%;
  /* float: left; */
  background: #eeefef;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 8px;
  height: 40px;
}

.card-total {
  width: 75%;
  border: none;
  background: none;
  font-size: 15px;
  font-weight: bold;
  margin-left: 5%;
  text-align: right;
  line-height: 1;
  height: 31.5px;
  outline: none !important;
}

.right-radius {
  border-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  overflow: hidden;
}

.left-radius {
  border-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  overflow: hidden;
}

.top-left-radius {
  border-top-left-radius: 5px !important;
}

.top-right-radius {
  border-top-right-radius: 5px !important;
}

.icon_set {
  /* display: inline-block; */
  margin: 0px;
  padding: 0px;
  /* width: 50%; */
  text-align: right;
}

.icon_set li {
  display: inline-block;
  font-size: 16px;
  padding: 0px 1px;
}

.icon_set li a {
  color: #1360ee;
  font-size: 20px;
  background: #e4e9fc;
  padding-inline: 5px;
  text-align: center;
  display: block;
  border: 1px solid #bbcce8;
  border-radius: 6px;
  margin-right: 2px;
  cursor: pointer;
  height: 40px;
  padding: 5px 8px;
}

.disabled-icon {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.55 !important;
}

.hidden-scroll {
  overflow: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle Thumb */

::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.visible-scroll-hover {
  /* margin-top: -2px; */
}

.visible-scroll-hover:hover::-webkit-scrollbar-thumb {
  /* background: gray !important;
  border-right: 1px solid #fff;
  border-left: 2px solid #fff;
  border-radius: 10px; */
}

.group-list-scroll:hover::-webkit-scrollbar-thumb {
  /* background: gray !important;
  border-left: 3px solid #eeedf2;
  border-radius: 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
}

/* ::-webkit-scrollbar {
  width: 8px !important;
  height: 5px;
  border-radius: 8px;
} */

.fc-scroller::-webkit-scrollbar {
  height: 12px;
}

/* scroll firefox */

:root :not(.fc-scroller){
  scrollbar-color: transparent transparent;
  scrollbar-width: thin !important;
}

.fc-scroller {
  scrollbar-color: gray transparent !important;
  scrollbar-width: thin !important;
}

.visible-scroll-hover:hover,
.group-list-scroll:hover {
  scrollbar-color: gray transparent;
  scrollbar-width: thin !important;
}

.group-list-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 15px);
  box-sizing: content-box;
  -ms-overflow-style: none;
  scrollbar-width: thin !important;
}

.dropdown .dropdown-menu.show::before {
  top: 0px;
  display: none;
}

.menu-card {
  padding: 0px 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.header-menu-icon {
  cursor: pointer;
  margin-left: 1.5rem;
}

.header-date {
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-left: 1.5rem;
  color: white;
  font-size: 13px;
}

.notification {
  background-color: #ea3f3f;
  color: white;
  border-radius: 50%;
  text-align: center;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 999;
  margin-top: -10px;
  font-size: 12px;
  /* right: 0; */
}

#dropdown-setting:after {
  display: none;
}

.theme-icon {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

#tri:after {
  border-color: #ffffff rgba(0, 0, 0, 0) !important;
  border-style: solid;
  border-width: 0 13px 13px;
  content: "";
  display: block;
  left: 70%;
  margin-left: -15px;
  position: absolute;
  top: -10px;
  width: 0;
  z-index: 1;
}

#tri-profile:after {
  border-color: #ffffff rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 0 13px 13px;
  content: "";
  display: block;
  left: 87%;
  margin-left: -15px;
  position: absolute;
  top: -10px;
  width: 0;
  z-index: 1;
}

.dropdown .dropdown-menu {
  left: auto !important;
  right: 0 !important;
}

.dropdown .dropdown-menu .dropdown-item-profile {
  text-align: center !important;
  width: 250px !important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #fff;
  color: black;
}

.profile-link {
  font-size: 13px;
  padding: 0.5rem 0.25rem !important;
  color: #626161 !important;
  text-align: center !important;
}

.profile-link:hover {
  color: black !important;
}

.border-vertical {
  border-top: 1px solid rgb(238, 238, 238) !important;
  border-bottom: 1px solid rgb(238, 238, 238) !important;
}

.text-black {
  color: black !important;
}

.text-theme {
  color: #3166ee !important;
}

.text-caps {
  text-transform: capitalize !important;
}

.iconSearch {
  color: gray;
  position: absolute;
  z-index: 9;
  right: 5%;
  margin-top: 9px;
  font-size: 15px;
  cursor: pointer;
}

.vehicle-container {
  border-radius: 5px;
  background-color: #ffffff;
  border-collapse: separate;
  border-spacing: 0 2px;
}

.vehicle-container tr {
  border-bottom-width: 0px;
}

/* .vehicle-container td {
  background-color: #ffffff;
} */

/* Tab */

.react-tabs__tab {
  padding: 2px 4px !important;
  border: none !important;
  /* width: 33.33%;   */
}

.react-tabs__tab-list {
  border: none !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-inline: -4px !important;
}

.react-tabs__tab--selected {
  background-color: transparent !important;
}

.react-tabs__tab--selected:after {
  /* border-color: #caddfe rgba(0, 0, 0, 0) !important;
  border-style: solid;
  border-width: 0 10px 10px; */
  border-bottom: 17px solid #b6cdf5;
  border-right: 17px solid transparent;
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  left: 40%;
  width: 0;
  z-index: 1;
  transform: rotate(-45deg);
  /* -webkit-transform: rotate(-180deg); */
  border-bottom-left-radius: 5px;
}

.react-tabs__tab--selected .main-tab-chldrn {
  color: #3964c2 !important;
  background-color: #b6cdf5 !important;
}

.main-tab-chldrn {
  font-family: "Poppins", sans-serif !important;
}

.logo-image {
  height: 40px;
  width: auto;
}

.p-0 {
  padding: 0px !important;
}

.p-4p {
  padding: 4px !important;
}

.vh {
  position: relative !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.check-box-lab-ac:before {
  content: "";
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid white;
  display: inline-block;
  border-radius: 3px;
}

.check--box-lab:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #c0dfff;
  border: 1px solid #c0dfff;
  display: inline-block;
  border-radius: 3px;
}

input[type="checkbox"]:checked ~ label:before {
  background: #c0dfff no-repeat center;
  background-size: 12px 12px;
  background-image: url("./assets/img/check.png");
}

.moving-check--box-lab:before {
  content: "";
  width: 20px;
  height: 20px;
  background: #c0dfff;
  border: 1px solid #c0dfff;
  display: inline-block;
  border-radius: 3px;
}

.moving input[type="checkbox"]:checked ~ label:before {
  background: #0cbe0e no-repeat center !important;
  background-size: 12px 12px !important;
  background-image: url("./assets/img/check-white.png") !important;
}

.parking input[type="checkbox"]:checked ~ label:before {
  background: #ed1e10 no-repeat center !important;
  background-size: 12px 12px !important;
  background-image: url("./assets/img/check-white.png") !important;
}

.idling input[type="checkbox"]:checked ~ label:before {
  background: #f5a713 no-repeat center !important;
  background-size: 12px 12px !important;
  background-image: url("./assets/img/check-white.png") !important;
}

.chk-blue input[type="checkbox"] ~ label:before {
  background: #1360ee no-repeat center;
  background-size: 12px 12px;
}

.chk-blue input[type="checkbox"]:checked ~ label:before {
  background: #1360ee no-repeat center;
  background-size: 12px 12px;
  background-image: url("./assets/img/check-white.png");
}

.chk-bordered input[type="checkbox"] ~ label:before {
  background: #f2f7ff no-repeat center;
  border: 1px solid #1360ee;
  background-size: 12px 12px;
}

.chk-bordered input[type="checkbox"]:checked ~ label:before {
  background: #1360ee no-repeat center;
  background-size: 12px 12px;
  background-image: url("./assets/img/check-white.png");
}

.chk-white input[type="checkbox"] ~ label:before {
  background: #fcfcfc no-repeat center;
  border: 1px solid #1360ee;
  background-size: 12px 12px;
}

.chk-white input[type="checkbox"]:checked ~ label:before {
  background: #1360ee no-repeat center;
  background-size: 12px 12px;
  background-image: url("./assets/img/check-white.png");
}

.chk-bordered input[type="checkbox"] ~ label:before {
  border: 1px solid #83a8ee !important;
}

.check--box-lab {
  position: relative;
  top: 9px;
  left: -4px;
}

.check-box-lab-ac {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.Collapsible__trigger:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 20px;
  top: 9px;
  display: block;
  transition: transform 300ms;
}

.group-checkbox {
  position: relative;
  top: 4px;
  left: -1;
}

.footer-search {
  /* background: #eeedf2; */
  background: transparent;
  position: fixed;
  bottom: 2%;
  width: 25.5%;

  /* margin-top: -10px;
  position: relative;
  bottom: -25px;
  width: 100%; */
}

.modal-90w {
  width: 90% !important;
  text-align: center;
}

.modal-70w {
  width: 70% !important;
  text-align: center;
}

.modal-60w {
  width: 60% !important;
  text-align: center;
}

.modal-50w {
  width: 50% !important;
  text-align: center;
}

.modal-40w {
  width: 40% !important;
  text-align: center;
}

.modal-30w {
  width: 30% !important;
  text-align: center;
}

.modal-35w {
  width: 35% !important;
  text-align: center;
}

.r-5 {
  border-radius: 5px !important;
}

.m-h-70 {
  max-height: 70% !important;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.modal-backdrop.show {
  z-index: 1050 !important;
}

.modal-dialog {
  max-width: 100% !important;
}

.modal-content {
  min-width: 100% !important;
}

fieldset {
  padding: 0.35em 0 0.5em 0.625em !important;
  margin: 15px 0 0 !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 4px !important;
  float: left;
  width: 100%;
}

legend {
  margin: 0 !important;
  font-size: 11px !important;
  color: #989898 !important;
  border: none !important;
  width: auto !important;
  line-height: inherit;
  margin-top: -15px !important;
  background-color: rgb(255, 255, 255);
  padding: 0px 3px !important;
}

fieldset input {
  width: 100% !important;
  border: none !important;
  color: #242424 !important;
}

.settings_select_field {
  color: #000;
  font-size: 13px;
  padding: 0;
  width: 99%;
  outline: none;
  border-radius: 4px !important;
  border: none;
  height: 22px;
}

.btn-add-driver {
  padding: 12px 0;
  float: right;
  margin-top: 14px;
  width: 90%;
  font-weight: bold;
  background-color: #f1f6ff !important;
  color: #5188f0 !important;
  border: 1px solid #dbe8fe !important;
  font-size: 12px !important;
  font-weight: bold !important;
  height: 43px;
}

.driver-profile-img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
}

.driver-profile-img-container {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  height: 110px;
  width: 110px;
  padding-top: 4px;
  text-align: center;
  vertical-align: middle;
  margin-left: 45%;
}

.after-none {
}

.after-none:after {
  display: none !important;
}

.trip-left {
  height: 100%;
  border-top-right-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
  border-right: 2px solid gray !important;
}

.trip {
  height: 100%;
  /* width: 100%; */
}

.date-picker-container {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.replay-filter .react-datepicker-popper {
  z-index: 99 !important;
}

.trip .react-datepicker__input-container input {
  border: none !important;
  outline: none !important;
  height: 35px;
  padding-left: 10px;
  background-color: transparent !important;
}

.storage-date-picker {
  height: 30px;
  padding-left: 10px;
  background-color: transparent !important;
}

.bottomCard {
  padding: 10px;
  border: 1px solid rgb(204, 204, 204);
  position: absolute;
  width: 100% !important;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  background-color: #ffffff;
  padding-bottom: 20px;
  bottom: 38px;
}

.display-none {
  display: none !important;
}

.border-icon {
  border: 2px solid gray;
  line-height: 0.5;
  border-radius: 3px;
}

.trip-item-header {
  padding: 5px 0px;
  background-color: #e2e2e2;
  border-radius: 5px;
}

.trip-item-footer {
  padding: 5px 5px;
  background-color: #ffecc7;
}

.text-light-secondary {
  color: #a0a0a0;
}

.trip-item .trip-timing-main:hover {
  border: 1px solid #9abdfd;
  /* border-radius: 3px; */
  background-color: #eaf1fe;
}

.trip-item .trip-timing-main.active {
  border: 1px solid #9abdfd;
  border-radius: 3px;
  background-color: #eaf1fe;
}

.trip-item .trip-timing-main.active .trip-timing {
  color: #1360ee !important;
}

.trip-timing {
  padding: 0.5rem 1rem;
  font-size: 9px !important;
}

.trip-item .trip-timing-main.active .play-trip-icon {
  display: block !important;
  float: right;
  margin-right: 5px;
}

.trip-item .trip-timing-main:hover .play-trip-icon {
  display: block !important;
  float: right;
  margin-right: 5px;
}

.trip-item td {
  padding: 5px;
}

.not-hover {
  pointer-events: none;
}

.btn-play {
  color: #1360ee;
}

.alert-container {
  background-color: #ffffff;
  border-bottom: 1px solid #caddfe;
}

.alert-container.active {
  background-color: #f3f7ff;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  margin-left: 0px !important;
}

#navbar {
  margin-right: -30px !important;
}

.toggle-btn:after {
  font-family: "FontAwesome";
  position: absolute;
  content: "\f0d7" !important;
  top: 5px;
  right: 5%;
  font-size: 16px;
  transition: transform 300ms;
}

.toggle-btn-trip-alert:after {
  font-family: "FontAwesome";
  position: relative;
  content: "\f0d7" !important;
  float: right;
  top: -2px;
  margin-right: 5px;
  font-size: 16px;
  transition: transform 300ms;
}

.report-text {
  display: block;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicles-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.vehicles-list li {
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
}

.table-alerts {
  width: 100%;
  margin-bottom: 15px;
}

.table-alerts tr td {
  padding: 5px 8px;
}

.table-alerts tr:nth-child(even) {
  background-color: #eef3ff;
}

.reports {
  padding: 0;
}

.reports li {
  font-weight: normal;
  list-style: none;
  padding: 3px 20px;
}

.reports li:hover {
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.fleet-footer button {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: gray;
}

.focus-style-none:focus {
  outline: none !important;
  box-shadow: none !important;
}

.react-switch-bg {
  height: 17px !important;
  width: 30px !important;
}

.react-switch-handle {
  height: 15px !important;
  width: 15px !important;
}

.switch-on .react-switch-handle {
  transform: translateX(14px) !important;
}

/* settings */

.setting-navbar {
  padding-top: 10px;
}

.setting-navbar img {
  height: 12px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.setting-navbar li {
  list-style: none;
  padding: 10px 0px;
  color: #000;
  cursor: pointer;
}

.setting-navbar li:hover {
  color: #1a60eb;
}

.active-setting-tab {
  color: #1a60eb !important;
}

.setting-card {
  height: 75vh;
  background: #fff !important;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
  overflow-y: auto;
}

.profile-image {
  height: 75px;
  width: 75px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #1360ee;
  background-size: contain;
  overflow: hidden;
}

.profile-image-container {
  height: 75px;
  width: 75px;
  margin: 0 auto 25px;
  position: relative;
}

.profile-image img {
  border-radius: 50%;
}

.camera {
  position: absolute !important;
  bottom: -10px;
  left: 33%;
  align-self: center;
}

/* dashboard setting */

.dashboard-settings-select-container {
  display: inline-block;
  overflow: unset;
  margin: 0;
  background: #fff;
  border: 1px solid #e2e2e2;
  color: #989898;
  position: relative;
  border-radius: 3px 0 0 3px;
  width: 88%;
  border-right: 0;
}

.dashboard-settings-select-container::after {
  content: "▼";
  padding: 0px 8px;
  position: absolute;
  right: 3px;
  top: 6px;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 70%;
  pointer-events: none;
  font-size: 12px;
  background-color: #ddd;
}

.dashboard_settings_select_field {
  color: #000;
  font-size: 13px;
  padding: 0;
  width: 100%;
  outline: none;
  border-radius: 4px !important;
  border: none;
  height: 28px;
  background-color: #ddd;
}

.dashboard-setting-content {
  max-height: 90%;
  overflow-y: auto;
}

.dashboard-footer {
  position: absolute !important;
  bottom: 0;
  right: 25px;
}

/* Notifications Setting */

.account-list {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  width: 100%;
  height: 62px;
}

.account-list:hover {
  border: 1px solid #9abdff;
  background: #eaf1ff;
}

.account-option {
  display: none;
}

.account-list:hover .account-option {
  display: block !important;
  border-left: 1px solid #9abdff;
}

.hidden-toggle {
}

.hidden {
  display: none;
}

.hidden-toggle:hover .hidden {
  display: block !important;
}

.edit-container {
  background: #d3e2ff;
  background-image: url(assets/img/settings/edit-icon.svg);
  background-repeat: no-repeat;
  margin: 3px 0px;
  width: 25px;
  height: 25px;
  background-position: center;
  border-radius: 50%;
}

.delete-container {
  background: #ffc3be;
  background-image: url(assets/img/settings/delete-icon.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  background-position: center;
  border-radius: 50%;
}

.star-container {
  background: #ffeece;
  background-image: url(assets/img/settings/star.svg);
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  background-position: center;
  border-radius: 50%;
}

.star-outline-container {
  background: #ffeece;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}

/* Report Setting */

.report-img {
  background: url(assets/img/settings/report.svg) no-repeat;
  height: 60px;
  background-position: center;
}

.account-list:hover .report-img {
  background: url(assets/img/settings/report_active.svg) no-repeat;
  height: 60px;
  background-position: center;
}

/* Account Setting */

.user-img {
  background: url(assets/img/settings/profile.svg) no-repeat;
  height: 60px;
  background-position: center;
}

.account-list:hover .user-img {
  background: url(assets/img/settings/profile_active.svg) no-repeat;
  height: 60px;
  background-position: center;
}

.user-img-active {
  background: url(assets/img/settings/profile_main.svg) no-repeat !important;
  height: 60px;
  background-position: center;
}

.account-list:hover .user-img-active {
  background: url(assets/img/settings/profile_active_main.svg) no-repeat !important;
  height: 60px;
  background-position: center;
}

.border-bottom-user {
  border-bottom: 1px solid #9abdff !important;
}

.botder-right-user {
  border-right: 1px solid #9abdff !important;
}

/* live settings */

.group-table {
  width: 100%;
  border-radius: 5px;
}

.group-table tbody tr td:first-child {
  padding: 8px;
  padding-left: 10px !important;
  width: 80%;
}

.group-table tbody tr:nth-child(odd) {
  background-color: #f2f7ff;
}

/* Task Manager setting */

.shadow-none {
  box-shadow: none !important;
}

/* task manager */

.task-count-section {
  background-color: white;
  height: 50px;
  padding: 5px 0px;
  text-align: center;
}

.task-count-section img {
  margin-bottom: 2px;
}

.task-count-image {
  width: 30px;
  height: 30px;
  float: right;
  background: #d5e3ff;
  padding: 3.5px;
  border-radius: 50%;
}

.singleTaskActive {
  border: 1px solid #9abdff !important;
  background-color: #eaf1ff !important;
}

.taskordersectionActive {
  border: 1px solid #1360ee;
  background: #1360ee;
  color: #fff;
}

.task-outer {
  margin: 10px 0;
  overflow: hidden;
  border: 5px solid #cfe0ff;
  padding: 10px;
  border-radius: 10px;
  /* background: #ececec; */
  /* box-shadow: 0px 3px 5px rgb(218, 218, 218); */
  text-align: left;
}

.task_label {
  font-size: 12px;
  text-align: left;
  padding: 0;
  color: #1360ee;
  margin-top: 3px;
  width: 22%;
  font-weight: bold;
}

.task_text {
  font-size: 12px;
  font-weight: bold;
  color: #555555;
  width: 78%;
  margin-top: 3px;
}

.outline-none {
  outline: none !important;
}

.hide-control .gmnoprint {
  display: none !important;
}

.sidebar-width {
  /* width: 120px !important; */
}

.container-fluid-app {
}

.pr-0 {
  padding-right: 0px !important;
}

.max-100 {
  max-height: 100%;
  min-height: 100%;
}

/* .group-list-scroll::-webkit-scrollbar {
  display: none;
   Safari and Chrome
} */

.max-68vh {
  max-height: 68vh !important;
  min-height: 68vh !important;
}

.max-65vh {
  max-height: 65vh !important;
  min-height: 65vh !important;
}

.max-60vh {
  max-height: 60vh !important;
  min-height: 60vh !important;
}

.max-75vh {
  max-height: 75vh !important;
  min-height: 75vh !important;
}

.max100 {
  max-height: 92vh !important;
  overflow: hidden;
  margin-left: -8px !important;
  padding-right: 0px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.vehicles-lst {
  background: white;
  padding: 6px;
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.checkbox-vehicle {
  position: relative;
  top: -6px;
  float: left;
  margin-right: 4px;
}

.vehicles-lst:hover {
  box-shadow: 0 0 0 1pt #5188f0;
  border-radius: 3px solid #5188f0;
  background-color: #eaf1fd;
}

.vehicles-lst.active {
  box-shadow: 0 0 0 1pt #5188f0;
  border-radius: 3px solid #5188f0;
  background-color: #eaf1fd;
}

.Collapsible__contentOuter {
  background: #eeedf2;
}

.collapsible-98 .Collapsible__contentOuter {
  width: 100% !important;
}

.sidenav .navbar-nav {
  width: 100% !important;
}

.leaflet-control {
  display: none;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
}

.react-tabs__tab:focus::after {
  position: absolute !important;
  left: 40% !important;
  background-color: transparent !important;
}

.map-border {
  border-left: 4px solid #8dc3fb;
}

.even-bg {
  background-color: #f4f4f4;
}

.br-3 {
  border-radius: 3px;
}

.footer-table td {
  padding-bottom: 5px;
  vertical-align: top;
}

.scrolling-card {
  max-height: 230px;
  overflow-y: auto;
  min-height: 150px;
  overflow-x: hidden;
}

.loadWrapper {
  z-index: 1;
  width: 85px;
  height: 85px;
  margin: 0 auto;
  background: url(./assets/images/loader.svg) center no-repeat;
  z-index: 10000;
  position: absolute;
  top: calc(45%);
  left: calc(60%);
  margin-bottom: 10px;
  line-height: 2;
}

.loader-position {
  z-index: 1;
  width: 85px;
  height: 85px;
  margin: 0 auto;
  z-index: 10000;
  position: absolute;
  top: calc(45%);
  left: calc(60%);
  margin-bottom: 10px;
  line-height: 2;
}

.site-loader-text {
  color: #3964c2;
  top: 11vh;
  position: relative;
  text-align: center;
  font-size: 15px;
}

.bold {
  font-weight: bold;
}

.bold-600 {
  font-weight: 600;
}

.bold-500 {
  font-weight: 500;
}

.animatedMarker {
  -webkit-transition: transform 1s linear;
  -moz-transition: transform 1s linear;
  -o-transition: transform 1s linear;
  -ms-transition: transform 1s linear;
  transition: transform 1s linear;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.navbar {
  padding: 0px !important;
}

.react-tabs {
  height: 100%;
}

.react-tabs__tab-panel {
  height: 100%;
}

.bg-gray {
  background-color: #f8f8f8;
}

/* 
.leaflet-marker-pane img{
  border: 3px solid red !important;
  box-shadow: 0px 1px 5px 6px #eaeaea;
  width: 50px;
  height: 50px;
} */

.marker-lable-vehicle {
  display: block;
  /* width: 140px; */
  /* overflow: hidden; */
  white-space: nowrap;
  text-overflow: ellipsis;
}

.leaflet-marker-icon {
  position: relative;
  /* Affects Leaflet behaviour */
}

.marker-lable-moving {
  border-left: 3px solid #0acc1d;
  box-shadow: 1px 0.7px 7px #888888;
}

.marker-lable-parking {
  border-left: 3px solid #fd1b06;
  box-shadow: 1px 0.7px 7px #888888;
}

.marker-lable-idling {
  border-left: 3px solid #f3a62e;
  box-shadow: 1px 1px 10px #888888;
}

.eclips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-marker-icon {
  position: absolute !important;
  top: 30px !important;
  left: 0px !important;
  right: 0px !important;
}

.sigCanvas {
  cursor: cell;
}

.sign-image-view {
  object-fit: contain;
}

.leaflet-tooltip-bottom {
  position: absolute !important;
  top: 30px !important;
}

.h-34 .dropdown-heading {
  height: 34px !important;
}

.h-28 .dropdown-heading {
  height: 28px !important;
}

.w-250px .dropdown-heading {
  width: 250px !important;
}

.no-margin {
  margin: 0px !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.attachment-pdf .react-pdf__Page__canvas {
  height: 500px !important;
}

.no-wrap {
  white-space: nowrap;
}

.edit-button {
  position: absolute;
  right: 16px;
  top: 16px;
  background: #e8e8e8;
  padding: 12px;
  cursor: pointer;
}

.bottom-border {
  border-bottom: 1px solid rgb(226, 226, 226);
}

.select-item {
  padding: 5px 10px !important;
  font-weight: 500;
  font-size: 14px;
  margin: 5px !important;
}

.panel-content input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
  position: relative;
  top: 5px;
  border-radius: 5px;
}

.rdtPicker {
  min-width: 120px !important;
}

.pac-container {
  z-index: 9999999 !important;
}

.ad-driver-image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}

.trip-notification {
  position: absolute;
  z-index: 999999999;
  background: white;
  padding: 10px;
  margin: 20px;
  left: 45%;
  border-radius: 10px;
  /* width: 20%; */
  /* width: 45% !important; */
  box-shadow: 0px 5px 17px 0px #a0a0a0;
  font-size: 12px;
}

.trip-notification img {
  height: 40px;
  width: auto;
  padding: 0px;
  margin: 10px;
}

.flex-1 {
  flex: 1;
}

.gm-style .gm-style-iw-c button :not(.driverStatusCount) {
  border: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.semi-bold {
  font-weight: 500;
  letter-spacing: 0.2px;
}

.gm-bundled-control-on-bottom {
  bottom: 55vh !important;
}

.salik-map .gm-bundled-control-on-bottom {
  bottom: 167px !important;
}

/* Optimization location card */

.opt-location-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  margin-bottom: 10px;
}

.opt-location-label {
  font-weight: 500;
  font-size: 14px;
  color: #c4cad3;
}

.opt-location-value {
  font-size: 14px;
  color: #484848;
  margin-left: 5px;
}

/* Responsive */

.mobile-only {
  display: none;
}

.web-only {
  display: block;
}

.timeline-button {
  padding: 7px;
  margin-left: 10px;
  border-radius: 5px;
  background: #f3f7fa;
  border: 1px solid #cccccc;
}

.onTaskButton {
  background-color: #ffd5ea !important;
  border-bottom: 3px solid #ac0e63 !important;
  color: #ac0e63 !important;
  width: 20%;
}

.onTaskButton:hover {
  background-color: #ac0e63 !important;
  color: white !important;
}

.onTaskButtonDark {
  background-color: #ac0e63 !important;
  color: white !important;
}

.onTaskButton:after {
  border-color: #ac0e63 rgba(0, 0, 0, 0) !important;
}

.notAvailableButton {
  background-color: rgb(230, 230, 230) !important;
  border-bottom: 3px solid grey !important;
  color: grey !important;
  width: 20%;
}

.notAvailableButton:hover {
  background-color: grey !important;
  color: white !important;
}

.notAvailableButtonDark {
  background-color: grey !important;
  color: white !important;
}

.notAvailableButton:after {
  border-color: grey rgba(0, 0, 0, 0) !important;
}

.availableButton {
  background-color: #bdfdcf !important;
  border-bottom: 3px solid #36bf5d !important;
  color: #36bf5d !important;
  width: 20%;
}

.availableButton:hover {
  background-color: #36bf5d !important;
  color: white !important;
}

.availableButtonDark {
  background-color: #36bf5d !important;
  color: white !important;
}

.availableButton:after {
  border-color: #36bf5d rgba(0, 0, 0, 0) !important;
}

.header-multi-select .css-g1d714-ValueContainer,
.header-multi-select .css-1hwfws3 {
  height: 36px !important;
  overflow-y: auto !important;
}

.h-38px {
  height: 38px !important;
}

.align-item-center {
  align-items: center !important;
}

.share-location-loader {
  left: calc(45%);
}

.react-tel-input .form-control:focus,
.PhoneInputInput,
.PhoneInput input {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.PhoneInput {
  margin-top: 0.2em !important;
}

/* mui date picker style  */

.mui-date-picker fieldset:not(.visible){
  visibility: hidden !important;
}
.mui-date-picker input {
  padding: 0px 0px 0px 10px !important;
  font-size: 13px !important;
}
.mui-date-picker svg {
 height: 0.8em !important;
 width: 0.8em !important;
}
@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .web-only {
    display: none;
  }

  .modal-30w {
    width: 95% !important;
  }

  .share-location-loader {
    left: calc(40%);
  }
}

.print-only,
.print-only-flex {
  display: none !important;
}

@media print {
  .print-hidden {
    visibility: hidden;
  }

  .print-only {
    display: block !important;
  }

  .print-only-flex {
    display: flex !important;
  }
}

/* scrollbar */

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
  border: 3px solid #f1f1f1 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1 !important;
}
