.sidebar-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.immobilizer-icon {
  height: 32px;
  width: 32px;
  object-fit: contain;
}

.downward {
  height: 30px;
  width: 30px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  z-index: 999;
  bottom: 20px;
  left: 50px;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
}
