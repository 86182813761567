/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  height: 100vh;
  padding-top: 10%;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  margin: auto;
  background-image: url(./not_found.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #3166ee;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

/* 503 */
.page_503 {
  margin: auto;
  background-image: url(./maintenance_bg.jpg);
  height: 100vh;
  background-position: center;
}

.subscription_expired_bg {
  margin: auto;
  background-image: url(./subscription-expired.png);
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
}

.site_broken_bg {
  margin: auto;
  background-image: url(./site-broken.jpeg);
  height: 700px;
  background-position: center;
  background-repeat: no-repeat;
}

.five_zero_three_bg {
  margin: auto;
  background-image: url(./maintenance-vector.png);
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
}

.maintenance-title {
  font-weight: 500;
  font-size: 20px;
  color: #707070;
  text-align: center;
  margin-bottom: 20px;
}

.maintenance-title-2 {
  color: #000000;
  font-weight: 700;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  word-break: break-word;
  margin-bottom: 20px;
}

.maintenance-message {
  color: #454545;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}