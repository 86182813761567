.distance-report-view {
    margin-bottom: 10px;
}

.distance-report-header {
    padding: 5px;
    color: #4780f0 !important;
    border-radius: 4px 4px 0 0;
    background: #cbdcfe;
    font-weight: bold;
    font-size: 12px;
}

.distance-report-group-header {
    padding: 5px;
    color: #fff !important;
    border-radius: 4px;
    background: #6e7482;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
}

.table thead th:not(.not-default) {
    padding: 2px 6px !important;
    font-size: 12px;
}

.table tbody td {
    padding: 6px;
    font-size: 13px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #eef3ff;
    color: #000000;
}

.table> :not(caption)>*>* {
    box-shadow: none;
}

.white-space-normal {
    white-space: normal !important;
}

.td-w-10 div {
    width: 300px;
    white-space: initial;
}

.td-w-7 div {
    width: 210px;
    white-space: initial;
}

.td-w-14 div {
    width: 350px;
    white-space: initial;
}

.td-w-10p {
    width: 10%;
}

.td-w-5p {
    width: 5%;
}

.route-img {
    cursor: pointer;
    height: 30px;
    width: 30px;
}



.loader-view {
    position: absolute;
    /* left: 60%; */
    /* top: 40%; */
    z-index: 9;
    background: #ffffffb0;
    height: 77%;
    width: 68%;
}

.loader-view img {
    height: 220px;
    width: auto;
    margin-bottom: 15px;
}

.loading-text {
    padding: 5px 50px;
    background: #f1f1f1;
    color: blue;
    border-radius: 30px;
}

.pink {
    background-color: #ffeded !important;
}

.pink-tr {
    background-color: #ffeded !important;
}

.pink-tr td {
    /* max-width: 100px !important;
    overflow-x: scroll; */
    white-space: normal;
}

.pink-tr-info {
    margin: 0px;
    margin-right: 20px;
}

.count-table {
    margin-bottom: 0.7rem;
    margin-top: -5px;
}


table .text-center {
    vertical-align: middle !important;
}