.e-th {
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
    padding-block: 20px;
    font-size: 14px;
}

.e-td {
    text-align: center;
    white-space: nowrap;
    padding-block: 10px;
    margin-block: auto;
}

.e-td-description {
    text-align: center;
    white-space: nowrap;
    padding-block: 20px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.e-card-th {
    background-color: rgba(255, 255, 255, 0);
    width: 98%;
    margin-inline: auto;
}

.service-card {
    background-color: rgb(255, 255, 255);
    width: 98%;
    margin-inline: auto;
    border-radius: 5px;
    box-shadow: 0px 2px 8px 0px #6363631a;
    margin-bottom: 10px !important;
    font-size: 13px;
    border: 0px solid black;
}

.service-card.active {
    border: 1px solid #9ABDFF;
    background-color: #EAF1FF !important;
}


/* details */

.e-detail-card {
    width: 350px;
    height: 91vh;
    position: absolute;
    top: 8vh;
    right: 0;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    box-shadow: 1px 1px 10px #888888;

}

.e-detail-card-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.e-detail-content {
    overflow: auto;
    height: 84vh;
    padding-inline: 15px;
    padding-bottom: 20px;
}

.label {
    color: gray;
}

.btn-verify-expense {
    font-size: 12px;
    position: fixed;
    bottom: 20px;
    left: 65%;
}