.as-main-container {
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    height: 100vh;
}

.as-header {
    height: 8%;
    width: 100%;
    padding: 10px;

}

.as-body-content {
    height: 91.5%;
    width: 100%;
}

.as-body-content-no-sidebar {
    width: 99%;
    margin-inline: auto;
}

.as-body-sidebar {
    height: 100%;
    width: 8.5%;
    float: left;
    overflow-y: scroll !important;
    max-height: 100vh !important;
    padding-bottom: 80px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.as-body-sidebar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.as-body-sidebar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.as-body-data {
    height: 91vh;
    width: 91.25%;
    float: right;
    border-radius: 3px;
    background-color: #f3f7fa;
    margin-right: 0.25%;
}


.as-no-sidebar {
    position: relative;
    left: -28.36%;
}

.social-icons-header {
    position: absolute;
    right: 0;
}

/* Login */

.main-container-login {
    height: 100vh;
    width: 100%;
    height: 100vh;
    width: 100%;
}

.main-container-login-max {
    height: 100vh;
    width: 100%;
    height: 100vh;
    width: 100%;
}

.lottie_img {
    position: absolute;
    z-index: -1;
    width: 100%;
}

.full-page {
    height: 100vh;
    width: 100%;
}

.bg-gif {
    height: 100%;
    width: 100%;
}

.top-icon {
    height: 25px;
    margin: 15px 25px 0px 0px;
    cursor: pointer;
}

.login-card {
    background-color: #fff;
    width: 380px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #e6e5e7;
    padding: 20px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
    height: 550px;
    margin-right: 100px;
}

.logo {
    width: 200px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.login-input {
    outline: none;
    border-radius: 5px;
    border: 3px solid #f1f1f1;
    background-color: #f3f3f3;
    width: 100%;
    padding: 7px 10px;
    font-size: 12px;
    margin: 15px 0px
}

.bg-theme {
    background-color: #3166ee;
    text-transform: none;
}

.bottom-container {
    background-color: #3166ee;
    border-radius: 5px;
    padding: 4px 8px;
    cursor: pointer;
    width: 113px;
}

.bottom-icon {
    height: 12px;
    width: 12px;
    margin-top: 6px;
    margin-right: 5px;
}

.login-bottom-icon {
    height: 22px;
    width: 22px;
}

.bottom-h2 {
    color: #fff;
    font-size: 7px;
}

.bottom-h1 {
    color: #fff;
    font-size: 10px;
}

.ml {
    margin-left: 18%;
}

.mr {
    margin-right: 16%;
}

.main-container-login {
    animation-name: loginBG;
    animation-duration: 3s;
    /* background:radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 90%) 100%); */
}

.login-remember {
    margin-top: -10px;
    margin-left: 8px;
}

.login-remember .form-check-label {
    position: relative;
    left: -30%;
}

.login-form {
    width: 100% !important;
    padding: 20px !important;
}

.show-password-eye {
    position: relative;
    right: -130px;
    top: -45px;
    padding: 6px;
    font-size: 13px;
    cursor: pointer;
    color: #537be4;
}

@keyframes loginBG {
    0% {
        background: radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 70%) 100%);
    }

    10% {
        background: radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 70%) 100%);
    }

    20% {
        background: radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 70%) 100%);
    }

    30% {
        background: radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 70%) 100%);
    }

    31% {
        background: radial-gradient(rgb(235 197 254 / 69%) 28%, rgb(164 156 255 / 69%) 65%, rgb(188 167 255 / 69%) 100%);
    }

    32% {
        background: radial-gradient(rgb(235 197 254 / 68%) 28%, rgb(164 156 255 / 68%) 65%, rgb(188 167 255 / 68%) 100%);
    }

    33% {
        background: radial-gradient(rgb(235 197 254 / 67%) 28%, rgb(164 156 255 / 67%) 65%, rgb(188 167 255 / 67%) 100%);
    }

    34% {
        background: radial-gradient(rgb(235 197 254 / 66%) 28%, rgb(164 156 255 / 66%) 65%, rgb(188 167 255 / 66%) 100%);
    }

    35% {
        background: radial-gradient(rgb(235 197 254 / 65%) 28%, rgb(164 156 255 / 65%) 65%, rgb(188 167 255 / 65%) 100%);
    }

    36% {
        background: radial-gradient(rgb(235 197 254 / 64%) 28%, rgb(164 156 255 / 64%) 65%, rgb(188 167 255 / 64%) 100%);
    }

    37% {
        background: radial-gradient(rgb(235 197 254 / 63%) 28%, rgb(164 156 255 / 63%) 65%, rgb(188 167 255 / 63%) 100%);
    }

    38% {
        background: radial-gradient(rgb(235 197 254 / 62%) 28%, rgb(164 156 255 / 62%) 65%, rgb(188 167 255 / 62%) 100%);
    }

    39% {
        background: radial-gradient(rgb(235 197 254 / 61%) 28%, rgb(164 156 255 / 61%) 65%, rgb(188 167 255 / 61%) 100%);
    }

    40% {
        background: radial-gradient(rgb(235 197 254 / 60%) 28%, rgb(164 156 255 / 60%) 65%, rgb(188 167 255 / 60%) 100%);
    }

    41% {
        background: radial-gradient(rgb(235 197 254 / 59%) 28%, rgb(164 156 255 / 59%) 65%, rgb(188 167 255 / 59%) 100%);
    }

    42% {
        background: radial-gradient(rgb(235 197 254 / 58%) 28%, rgb(164 156 255 / 58%) 65%, rgb(188 167 255 / 58%) 100%);
    }

    43% {
        background: radial-gradient(rgb(235 197 254 / 57%) 28%, rgb(164 156 255 / 57%) 65%, rgb(188 167 255 / 57%) 100%);
    }

    44% {
        background: radial-gradient(rgb(235 197 254 / 56%) 28%, rgb(164 156 255 / 56%) 65%, rgb(188 167 255 / 56%) 100%);
    }

    45% {
        background: radial-gradient(rgb(235 197 254 / 55%) 28%, rgb(164 156 255 / 55%) 65%, rgb(188 167 255 / 55%) 100%);
    }

    46% {
        background: radial-gradient(rgb(235 197 254 / 54%) 28%, rgb(164 156 255 / 54%) 65%, rgb(188 167 255 / 54%) 100%);
    }

    47% {
        background: radial-gradient(rgb(235 197 254 / 53%) 28%, rgb(164 156 255 / 53%) 65%, rgb(188 167 255 / 53%) 100%);
    }

    48% {
        background: radial-gradient(rgb(235 197 254 / 52%) 28%, rgb(164 156 255 / 52%) 65%, rgb(188 167 255 / 52%) 100%);
    }

    49% {
        background: radial-gradient(rgb(235 197 254 / 51%) 28%, rgb(164 156 255 / 51%) 65%, rgb(188 167 255 / 51%) 100%);
    }

    50% {
        background: radial-gradient(rgb(235 197 254 / 50%) 28%, rgb(164 156 255 / 50%) 65%, rgb(188 167 255 / 50%) 100%);
    }

    51% {
        background: radial-gradient(rgb(235 197 254 / 49%) 28%, rgb(164 156 255 / 49%) 65%, rgb(188 167 255 / 49%) 100%);
    }

    52% {
        background: radial-gradient(rgb(235 197 254 / 48%) 28%, rgb(164 156 255 / 48%) 65%, rgb(188 167 255 / 48%) 100%);
    }

    53% {
        background: radial-gradient(rgb(235 197 254 / 47%) 28%, rgb(164 156 255 / 47%) 65%, rgb(188 167 255 / 47%) 100%);
    }

    54% {
        background: radial-gradient(rgb(235 197 254 / 46%) 28%, rgb(164 156 255 / 46%) 65%, rgb(188 167 255 / 46%) 100%);
    }

    55% {
        background: radial-gradient(rgb(235 197 254 / 45%) 28%, rgb(164 156 255 / 45%) 65%, rgb(188 167 255 / 45%) 100%);
    }

    56% {
        background: radial-gradient(rgb(235 197 254 / 44%) 28%, rgb(164 156 255 / 44%) 65%, rgb(188 167 255 / 44%) 100%);
    }

    57% {
        background: radial-gradient(rgb(235 197 254 / 43%) 28%, rgb(164 156 255 / 43%) 65%, rgb(188 167 255 / 43%) 100%);
    }

    58% {
        background: radial-gradient(rgb(235 197 254 / 42%) 28%, rgb(164 156 255 / 42%) 65%, rgb(188 167 255 / 42%) 100%);
    }

    59% {
        background: radial-gradient(rgb(235 197 254 / 41%) 28%, rgb(164 156 255 / 41%) 65%, rgb(188 167 255 / 41%) 100%);
    }

    60% {
        background: radial-gradient(rgb(235 197 254 / 40%) 28%, rgb(164 156 255 / 40%) 65%, rgb(188 167 255 / 40%) 100%);
    }

    61% {
        background: radial-gradient(rgb(235 197 254 / 39%) 28%, rgb(164 156 255 / 39%) 65%, rgb(188 167 255 / 39%) 100%);
    }

    62% {
        background: radial-gradient(rgb(235 197 254 / 38%) 28%, rgb(164 156 255 / 38%) 65%, rgb(188 167 255 / 38%) 100%);
    }

    63% {
        background: radial-gradient(rgb(235 197 254 / 37%) 28%, rgb(164 156 255 / 37%) 65%, rgb(188 167 255 / 37%) 100%);
    }

    64% {
        background: radial-gradient(rgb(235 197 254 / 36%) 28%, rgb(164 156 255 / 36%) 65%, rgb(188 167 255 / 36%) 100%);
    }

    65% {
        background: radial-gradient(rgb(235 197 254 / 35%) 28%, rgb(164 156 255 / 35%) 65%, rgb(188 167 255 / 35%) 100%);
    }

    66% {
        background: radial-gradient(rgb(235 197 254 / 34%) 28%, rgb(164 156 255 / 34%) 65%, rgb(188 167 255 / 34%) 100%);
    }

    67% {
        background: radial-gradient(rgb(235 197 254 / 33%) 28%, rgb(164 156 255 / 33%) 65%, rgb(188 167 255 / 33%) 100%);
    }

    68% {
        background: radial-gradient(rgb(235 197 254 / 32%) 28%, rgb(164 156 255 / 32%) 65%, rgb(188 167 255 / 32%) 100%);
    }

    69% {
        background: radial-gradient(rgb(235 197 254 / 31%) 28%, rgb(164 156 255 / 31%) 65%, rgb(188 167 255 / 31%) 100%);
    }

    70% {
        background: radial-gradient(rgb(235 197 254 / 30%) 28%, rgb(164 156 255 / 30%) 65%, rgb(188 167 255 / 30%) 100%);
    }

    71% {
        background: radial-gradient(rgb(235 197 254 / 29%) 28%, rgb(164 156 255 / 29%) 65%, rgb(188 167 255 / 29%) 100%);
    }

    72% {
        background: radial-gradient(rgb(235 197 254 / 28%) 28%, rgb(164 156 255 / 28%) 65%, rgb(188 167 255 / 28%) 100%);
    }

    73% {
        background: radial-gradient(rgb(235 197 254 / 27%) 28%, rgb(164 156 255 / 27%) 65%, rgb(188 167 255 / 27%) 100%);
    }

    74% {
        background: radial-gradient(rgb(235 197 254 / 26%) 28%, rgb(164 156 255 / 26%) 65%, rgb(188 167 255 / 26%) 100%);
    }

    75% {
        background: radial-gradient(rgb(235 197 254 / 25%) 28%, rgb(164 156 255 / 25%) 65%, rgb(188 167 255 / 25%) 100%);
    }

    76% {
        background: radial-gradient(rgb(235 197 254 / 24%) 28%, rgb(164 156 255 / 24%) 65%, rgb(188 167 255 / 24%) 100%);
    }

    77% {
        background: radial-gradient(rgb(235 197 254 / 23%) 28%, rgb(164 156 255 / 23%) 65%, rgb(188 167 255 / 23%) 100%);
    }

    78% {
        background: radial-gradient(rgb(235 197 254 / 22%) 28%, rgb(164 156 255 / 22%) 65%, rgb(188 167 255 / 22%) 100%);
    }

    79% {
        background: radial-gradient(rgb(235 197 254 / 21%) 28%, rgb(164 156 255 / 21%) 65%, rgb(188 167 255 / 21%) 100%);
    }

    80% {
        background: radial-gradient(rgb(235 197 254 / 20%) 28%, rgb(164 156 255 / 20%) 65%, rgb(188 167 255 / 20%) 100%);
    }

    81% {
        background: radial-gradient(rgb(235 197 254 / 19%) 28%, rgb(164 156 255 / 19%) 65%, rgb(188 167 255 / 19%) 100%);
    }

    82% {
        background: radial-gradient(rgb(235 197 254 / 18%) 28%, rgb(164 156 255 / 18%) 65%, rgb(188 167 255 / 18%) 100%);
    }

    83% {
        background: radial-gradient(rgb(235 197 254 / 17%) 28%, rgb(164 156 255 / 17%) 65%, rgb(188 167 255 / 17%) 100%);
    }

    84% {
        background: radial-gradient(rgb(235 197 254 / 16%) 28%, rgb(164 156 255 / 16%) 65%, rgb(188 167 255 / 16%) 100%);
    }

    85% {
        background: radial-gradient(rgb(235 197 254 / 15%) 28%, rgb(164 156 255 / 15%) 65%, rgb(188 167 255 / 15%) 100%);
    }

    86% {
        background: radial-gradient(rgb(235 197 254 / 14%) 28%, rgb(164 156 255 / 14%) 65%, rgb(188 167 255 / 14%) 100%);
    }

    87% {
        background: radial-gradient(rgb(235 197 254 / 13%) 28%, rgb(164 156 255 / 13%) 65%, rgb(188 167 255 / 13%) 100%);
    }

    88% {
        background: radial-gradient(rgb(235 197 254 / 12%) 28%, rgb(164 156 255 / 12%) 65%, rgb(188 167 255 / 12%) 100%);
    }

    89% {
        background: radial-gradient(rgb(235 197 254 / 11%) 28%, rgb(164 156 255 / 11%) 65%, rgb(188 167 255 / 11%) 100%);
    }

    90% {
        background: radial-gradient(rgb(235 197 254 / 10%) 28%, rgb(164 156 255 / 10%) 65%, rgb(188 167 255 / 10%) 100%);
    }

    91% {
        background: radial-gradient(rgb(235 197 254 / 9%) 28%, rgb(164 156 255 / 9%) 65%, rgb(188 167 255 / 9%) 100%);
    }

    92% {
        background: radial-gradient(rgb(235 197 254 / 8%) 28%, rgb(164 156 255 / 8%) 65%, rgb(188 167 255 / 8%) 100%);
    }

    93% {
        background: radial-gradient(rgb(235 197 254 / 7%) 28%, rgb(164 156 255 / 7%) 65%, rgb(188 167 255 / 7%) 100%);
    }

    94% {
        background: radial-gradient(rgb(235 197 254 / 6%) 28%, rgb(164 156 255 / 6%) 65%, rgb(188 167 255 / 6%) 100%);
    }

    95% {
        background: radial-gradient(rgb(235 197 254 / 5%) 28%, rgb(164 156 255 / 5%) 65%, rgb(188 167 255 / 5%) 100%);
    }

    96% {
        background: radial-gradient(rgb(235 197 254 / 4%) 28%, rgb(164 156 255 / 4%) 65%, rgb(188 167 255 / 4%) 100%);
    }

    97% {
        background: radial-gradient(rgb(235 197 254 / 3%) 28%, rgb(164 156 255 / 3%) 65%, rgb(188 167 255 / 3%) 100%);
    }

    98% {
        background: radial-gradient(rgb(235 197 254 / 2%) 28%, rgb(164 156 255 / 2%) 65%, rgb(188 167 255 / 2%) 100%);
    }

    99% {
        background: radial-gradient(rgb(235 197 254 / 1%) 28%, rgb(164 156 255 / 1%) 65%, rgb(188 167 255 / 1%) 100%);
    }

    100% {
        background: radial-gradient(rgb(235 197 254 / 0%) 28%, rgb(164 156 255 / 0%) 65%, rgb(188 167 255 / 0%) 100%);
    }

    /*     
    from {background:radial-gradient(rgb(235 197 254 / 70%) 28%, rgb(164 156 255 / 70%) 65%, rgb(188 167 255 / 90%) 100%);}
    to {background:radial-gradient(rgb(235 197 254 / 0%) 28%, rgb(164 156 255 / 0%) 65%, rgb(188 167 255 / 0%) 100%);} */

}



.locator-toaster {
    width: 300px;
    background: #f1853b;
    padding: 15px;
    border-radius: 25px;
    color: white;
    background-repeat: no-repeat;
}

.locator-toaster h5 {
    color: white;
}

.sun-icon-div {
    background: #cc572d;
    height: 60px;
    width: 60px;
    border-radius: 40px;
    position: absolute;
    top: -8%;
    left: 2%;
}

.sun-icon-div img {
    height: 26px;
    margin-top: 6px;
}

.no-right-border {
    margin-right: 0px !important;
}

.no-right-border .as-w-99-8 {
    width: 100% !important;
}

.no-right-border .as-content-map-view {
    /* border-left: none !important; */
    border: none !important;
}

.expired-text {
    position: absolute;
    top: 3vh;
    right: 10px;
    color: #fff;
    font-size: 14px;
}

.share-location-logo {
    text-align: center;
}

.share-location-logo img {
    height: 40px;
}

@media only screen and (max-width: 768px) {
    .expired-text {
        font-size: 12px;
    }

    .share-location-logo {
        text-align: left;
        padding-left: 15px;
    }

    .share-location-logo img {
        height: 35px;
    }
}