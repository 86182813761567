.fa-phone {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    background: #f3f7fa;
    padding: 4px;
    border-radius: 100%;
    color: #636363;
    font-size: 10px;
    margin-right: 3px;
}

.fa-envelope {
    background: #f3f7fa;
    padding: 4px;
    border-radius: 100%;
    color: #636363;
    font-size: 10px;
    margin-right: 3px;
}

.footer-icons{
    background: #f3f7fa;
    padding: 4px;
    font-size: 10px;
    border-radius: 100%;
    height: 20px;
    width: 20px;
}