.other-setting-table tr th {
    font-size: 14px ;
    font-weight: 500 !important;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 3px 0px;
    color: gray;
}

.other-setting-table tr td {
    font-size: 14px ;
    font-weight: normal !important;
    border-bottom: 1px solid #e6e6e6;
    padding: 3px 0px;
}

.no-data-placeholder{
    padding: 10px;
    line-height: 120px;
    text-align: center;
    font-style: italic;
    color: #ccc;
}

tspan {
    font-size: 11px !important;
}
