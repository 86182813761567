.activeButton:after {
  border-bottom: 12px solid red;
  border-right: 12px solid transparent;
  content: '';
  display: block;
  position: relative;
  top: 12px;
  margin: auto;
  width: 0;
  z-index: 1;
  margin-top: -14px;
  transform: rotate(-45deg);
  border-bottom-left-radius: 5px;
}

.activeTripFooter:after {
  border-style: solid;
  border-width: 0px 0px 16px 14px;
  content: '';
  display: block;
  position: absolute;
  bottom: 25px;
  width: 0;
  margin-left: 6%;
  z-index: 1;
  background-color: transparent;
  border-bottom-right-radius: 7px;
  transform: rotate(-135deg);
}

/* Buttons */

Button:focus {
  box-shadow: none !important;
}

Button:hover {
  box-shadow: none !important;
}

.successButton {
  background-color: #d3f1cb !important;
  color: #116900 !important;
}

.successButton:hover {
  background-color: #0cbe0e !important;
  color: white !important;
}

.successButtonDark {
  background-color: #0cbe0e !important;
  color: white !important;
}

.successButton:after {
  border-color: #0cbe0e rgba(0, 0, 0, 0) !important;
}

.primaryButton {
  background-color: #21a1ff !important;
  color: white !important;
}

.btn-download {
  background-color: #65C3DF !important;
  color: white !important;
}

.primaryButton:hover {
  background-color: #1a60eb !important;
  color: white !important;
}

.primaryButtonDark {
  background-color: #1a60eb !important;
}

.primaryButton:after {
  border-color: #1a60eb rgba(0, 0, 0, 0) !important;
}

.LastWeekButton {
  background-color: #fff5e3 !important;
  color: #7e5300 !important;
}

.LastWeekButton:hover {
  background-color: #fbd796 !important;
  color: white !important;
}

.fleet-header-right {
  position: relative;
  top: -7px;
}

.zone-map-button {
  position: absolute;
  z-index: 999;
  top: 90%;
  margin-left: 30%;
}

.zone-map-button-live {
  top: 10px !important;
  right: 390px !important;
  height: 40px !important;
}

.zone-map-button-live button {
  height: 40px !important;
}

.map-control-address-field {
  position: absolute;
  z-index: 999;
  top: 10px !important;
  width: 250px !important;
  height: 40px !important;
}


.date-button {
  height: 40px;
}

.LastWeekButtonDark {
  background-color: #fbd796 !important;
  color: white !important
}

.LastWeekButton:after {
  border-color: #fbd796 rgba(0, 0, 0, 0) !important;
}

.zonesButton {
  background-color: #eaa1ea !important;
  color: #a71ba7 !important;
  border-bottom: 3px solid #a71ba7 !important;
}

.zonesButton:hover {
  background-color: #a71ba7 !important;
  color: white !important;
}

.zonesButtonDark {
  background-color: #a71ba7 !important;
  color: white !important;
}

.zonesButton:after {
  border-color: #a71ba7 rgba(0, 0, 0, 0) !important;
}

.account-setting-btn {
  background-color: #e2e2e2 !important;
  color: #4f5462 !important
}

.account-setting-btn-active {
  background-color: #e2e2e2 !important;
  color: hsl(221, 55%, 49%) !important;
  background-color: #caddfe !important;
}

.account-setting-btn-active:after {
  margin-left: 45% !important;
  border-color: #caddfe rgba(0, 0, 0, 0) !important;
}

.plus-btn {
  color: white;
  background-color: #38d36b;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  padding-top: 4px;
}

.btn-dark-primary {
  background-color: rgb(6, 68, 195) !important;
  color: #ffffff !important;
}

.btn-theme {
  background-color: #1360EE !important;
  color: #ffffff !important;
}

.btn-theme-sm {
  font-size: 9px !important;
  padding: 4px 9px !important;
}



.btn-light-success {
  background-color: #42ca92 !important;
  color: #ffffff !important;
}

.btn-white {
  background-color: #ffffff !important;
  color: #333 !important;
  border: 1px solid #9ea7b3 !important;
}

.btn-refresh {
  background-color: #cbdcfe !important;
  border: 1px solid #cbdcfe !important;
  color: #2874c2 !important;
  height: 30px !important;
  padding-top: 0px !important;
  box-shadow: none !important;
}

.btn-assign-tasks {
  background-color: #1360EE !important;
  color: #ffffff !important;
  border-color: #1360EE !important;
}

.dash-settings-remove {
  float: right;
  background: #FFEEED;
  border: 1px solid #FFD5D1;
  border-radius: 0 3px 3px 0;
  width: 12%;
  height: 30px;
}

.btn-footer {
  border: none;
  background-color: transparent !important;
  font-size: 12px !important;
  color: gray !important;
}

.btn-notAssigned {
  outline: none;
  border: 1px solid gray;
  color: #5b5959;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
}

.btn-assigned {
  outline: none;
  border: 1px solid #1214b1;
  color: #1214b1;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #cae0f2;
}

.btn-accepted {
  outline: none;
  border: 1px solid #b002bf;
  color: #b002bf;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #f0c5f4ab;
}

.btn-partial {
  outline: none;
  border: 1px solid #FFA10D;
  color: #FFA10D;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #FFEECE;
}

.btn-completed {
  outline: none;
  border: 1px solid #0cbe0e;
  color: #0cbe0e;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #d3f1cb;
}

.btn-rejected {
  outline: none;
  border: 1px solid #ed1e10;
  color: #ed1e10;
  font-size: 11px;
  border-radius: 3px;
  white-space: nowrap;
  background-color: #ffebed;
}

/* Texts */

.text-dark-primary {
  color: rgb(6, 68, 195) !important;
}

.text-total {
  color: #2196f3 !important
}

.text-inprogress {
  color: #FF7700 !important
}

.text-done {
  color: #21AC00 !important
}

.text-primary {
  color: #2196f3 !important
}

.text-theme-primary {
  color: #2874c2 !important
}

.text-theme-secondary {
  color: #989898 !important;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

/* Border */

.border-bottom-theme1 {
  border-bottom: 1px solid rgb(204, 204, 204) !important;
}

.border-none {
  border: none !important;
}

/* hover Border */

.fleet-reminder {
  background-color: #ffffff;
  padding: 5px 15px;
  border: 1px solid #e0dcdc;
  border-radius: 5px
}

.fleet-reminder-danger {
  background-color: #faf3f3;
  padding: 5px 15px;
  border: 1px solid #fc2817;
  border-radius: 5px
}

.fleet-reminder:hover {
  background-color: #EAF1FF !important;
  border: 1px solid #9ABDFF !important;
}

.fleet-reminder-danger:hover {
  background-color: #ffecec !important;
  border-color: #fc2817 !important;
}


.hover-outline-task:hover {
  background-color: #EAF1FF !important;
  outline: 1px solid #9ABDFF !important;
}


.disable-driver-card{
  filter: grayscale(1);
  background: #d1d1d1;
  cursor: not-allowed !important;
}

.disable-driver-card:hover{
  background: #d1d1d1;
  outline: none;
  cursor: not-allowed !important;
}

.active-card {
  background-color: #EAF1FF !important;
  border: 1px solid #9ABDFF !important;
  border-radius: 5px !important;
  overflow: hidden;
}

.btn-transparant {
  box-shadow: none !important;
  background: transparent !important;
  color: white;
}

.btn-search {
  box-shadow: none !important;
  color: #326bf0 !important;
  border: 1px solid #dbe8fe !important;
}

/* background colors */

.bg-light-gray {
  background-color: #f3f7fa;
}

.chat-box-shadow {
  box-shadow: -1px 0px 20px 0px #d8e5ee;
}

.bg-chat {
  background-color: #f3f7fa;
}

/* borders */

.border-theme {
  border: 1px solid rgb(204, 204, 204) !important;
}

.border-left {
  border-left: 1px solid rgb(204, 204, 204) !important;
}

.border-bottom {
  border-bottom: 1px solid rgb(204, 204, 204) !important;
}

/* Radius */

.r-5 {
  border-radius: 5px !important;
}

.r-3 {
  border-radius: 3px !important;
}

/* position */

.custom-control-left {
  position: absolute;
  z-index: 4 !important;
  left: 50px;
  top: 12px;
  height: 40px;
  background: rgba(0, 0, 0, .5);
  font-size: 12px;
  color: #fff !important;
  border-radius: 8px;
  border: 1px solid gray;
  display: flex;
  width: 170px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.custom-control-left-white {
  position: absolute;
  z-index: 2 !important;
  left: 11px;
  top: 12px;
  height: 40px;
  background: #fff;
  font-size: 12px;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  width: 100px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.add-poi-btn {
  position: absolute !important;
  z-index: 1 !important;
  right: 390px !important;
  top: 10px;
  height: 40px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  padding: 7px 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  cursor: pointer;
}

.map {
  text-align: center;
  padding: 6px;
  width: 40%;
  border-right: 1px solid gray;
  cursor: pointer;
  outline: none !important;
}

.map-icon {
  background-color: '#fff';
  text-align: center;
  cursor: pointer;
  outline: none !important;
  padding: 7px 12px;
  border-right: 1px solid #dfdfdf;

}

.satelight {
  text-align: center;
  cursor: pointer;
  outline: none !important;
  padding: 7px 12px !important;
}

.map-active {
  font-weight: bold;
  background: rgba(0, 0, 0, .4);
  border-radius: 8px;
}

.map-checkbox[type=checkbox] {
  transform: scale(1.5);
}