.week-day{
    border: 1px solid #6c757d;
    margin: 0px 4px;
    padding: 3px;
    border-radius: 10px;
    width: 40px;
    height: 22px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: normal !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.week-day-active{
    background: #1360EE;
    color: white;
    border: 1px solid #1360EE;
}