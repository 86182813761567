.notifications-container {
    background-color: #fff;
    position: absolute;
    z-index: 9999;
    top: 6.5vh;
    left: 85px;
    border-radius: 10px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1) !important;
    padding: 10px;
}

.e-notifications-cards {
    max-height: 65vh;
    width: 340px;
    overflow: auto;
}

.e-notification-card {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
}

.e-notification-date {
    margin-bottom: 0px;
    font-size: 12px;
    color: rgb(66, 139, 202);
}

.e-notification-title {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
}

.e-notification-body {
    margin-bottom: 0px;
    color: gray;
}

.e-notification-downward {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #3166ee;
    padding-top: 1px;
    cursor: pointer;
    text-align: center;
}